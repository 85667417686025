import React, { useState } from 'react';
import styled from 'styled-components'
import Avatar, {allOptions} from 'avataaars'
import AvatarOptions from "./AvatarOptions";
import Customizer, {AvatarList} from "./Customizer";
import {
    AccessoriesType,
    AllPieces, ClotheColorType, ClotheType, EyebrowType, EyesType,
    FacialHairColor,
    FacialHairType,
    HairColorType,
    HatColorType, SkinType,
    TopType
} from "./Pieces";
import CustomAvatar from "./CustomAvatar";
import {HappyEmotion, NeutralEmotion, SadEmotion} from "./EmotionOption";

const Main = styled.div`
  display: flex;
  justify-content: center;
  ;
`;

const Container = styled.div`
  width: 360px;
  //height: 740px;
  //overflow: hidden;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  background-color: rgba(1,1,1,0.3);
`

const CurrentAvatar = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 0;
`;

const Content = styled.div`
  overflow: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

const Random = styled.button`
  background-color: #f44336;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
`;

const generateRandomAvatar = () => {
    return {
        topType: TopType.options[Math.floor(Math.random() * TopType.options.length)],
        accessoriesType: AccessoriesType.options[Math.floor(Math.random() * AccessoriesType.options.length)],
        hairColor: HairColorType.options[Math.floor(Math.random() * HairColorType.options.length)],
        hatColor: HatColorType.options[Math.floor(Math.random() * HatColorType.options.length)],
        facialHairType: FacialHairType.options[Math.floor(Math.random() * FacialHairType.options.length)],
        facialHairColor: FacialHairColor.options[Math.floor(Math.random() * FacialHairColor.options.length)],
        clotheType: ClotheType.options[Math.floor(Math.random() * ClotheType.options.length)],
        clotheColor: ClotheColorType.options[Math.floor(Math.random() * ClotheColorType.options.length)],
        eyeType: EyesType.options[Math.floor(Math.random() * EyesType.options.length)],
        eyebrowType: EyebrowType.options[Math.floor(Math.random() * EyebrowType.options.length)],
        skinColor: SkinType.options[Math.floor(Math.random() * SkinType.options.length)],
    }
}

function App() {

    const [avatarOptions, setAvatarOptions] = useState(new AvatarOptions(generateRandomAvatar()));

    const [currentPiece, setCurrentPiece] = useState(0);
    const nextPiece = () => {
        if (currentPiece !== AllPieces.length -1) {
            if (hasBeard(currentPiece + 1)) {
                setCurrentPiece(currentPiece + 2)
            } else {
                setCurrentPiece(currentPiece + 1);
            }
        }
    }
    const previousPiece = () => {
        if (currentPiece !== 0) {
            if (hasBeard(currentPiece - 1)) {
                setCurrentPiece(currentPiece - 2)
            } else {
                setCurrentPiece(currentPiece - 1);
            }
        }
    }

    const hasBeard = (index) => {
        return AllPieces[index].type === "facialHairColor" && avatarOptions.facialHairType === "Blank";
    }

    const emotions = [HappyEmotion, NeutralEmotion, SadEmotion];

    return (
      <Main>
    <Container>
        <Content>
            <h3>Avataar Creator Prototype</h3>
        </Content>
        <Header>
            <CurrentAvatar>
                <CustomAvatar big={true} avatarOptions={avatarOptions} emotion={HappyEmotion} />
          </CurrentAvatar>
        </Header>
        <Content>
            <Customizer
                avatarOptions={avatarOptions}
                piece={AllPieces[currentPiece]}
                setAvatarOptions={setAvatarOptions}
                previousPiece={previousPiece}
                nextPiece={nextPiece}
                currentPiece={currentPiece}
            />
        <Random onClick={() => setAvatarOptions(new AvatarOptions(generateRandomAvatar()))}>Zufällig</Random>
        <h4>Glücklich, Neutral, Traurig</h4>
        <p>Der Avatar des Chatbots wird je nach Verlauf zwischen den Emotionen Glücklich, Neutral und Traurig wechseln</p>

            <AvatarList>
                {emotions.map(emotion => {
                    return (
                        <CustomAvatar emotion={emotion} avatarOptions={avatarOptions} />
                    );
                })}
            </AvatarList>
        </Content>
    </Container>
      </Main>
  );
}

export default App;
