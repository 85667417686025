/**
 * Pieces model
 *
 */
export class Piece {
    constructor(data = {}) {
        this.type = null;
        this.options = null;
        this.label = null;

        Object.assign(this, data);
    }
}

export const TopType = new Piece({
    type: 'topType',
    label: 'Haare und Mützen',
    options: ["Eyepatch", "Hat", "Hijab", "LongHairBigHair", "LongHairBob", "LongHairBun", "LongHairCurly",
        "LongHairCurvy", "LongHairDreads", "LongHairFrida", "LongHairFro", "LongHairFroBand", "LongHairMiaWallace", "LongHairNotTooLong",
        "LongHairShavedSides", "LongHairStraight", "LongHairStraight2", "LongHairStraightStrand", "ShortHairDreads01",
        "ShortHairDreads02", "ShortHairFrizzle","ShortHairShaggyMullet", "ShortHairShortCurly", "ShortHairShortFlat",
        "ShortHairShortRound", "ShortHairShortWaved", "ShortHairSides", "ShortHairTheCaesar", "ShortHairTheCaesarSidePart", "Turban",
        "WinterHat1", "WinterHat2", "WinterHat3", "WinterHat4"],
    // without NoHair
})

export const AccessoriesType = new Piece({
    type: 'accessoriesType',
    label: 'Accessoires',
    options: ["Blank", "Kurt", "Prescription01", "Prescription02", "Round", "Sunglasses", "Wayfarers"],
})

export const HatColorType = new Piece({
    type: 'hatColor',
    label: 'Hutfarbe',
    options: ["Black", "Blue01", "Blue02", "Blue03", "Gray01", "Gray02", "Heather", "PastelBlue", "PastelGreen", "PastelOrange", "PastelRed", "PastelYellow", "Pink", "Red", "White"],
})

export const HairColorType = new Piece({
    type: 'hairColor',
    label: 'Haarfarbe',
    options: ["Auburn", "Black", "Blonde", "BlondeGolden", "Brown", "BrownDark", "PastelPink", "Platinum", "Red", "SilverGray"],
})

export const FacialHairType = new Piece({
    type: 'facialHairType',
    label: 'Bart',
    options: ["BeardLight", "BeardMajestic", "BeardMedium", "Blank", "MoustacheFancy", "MoustacheMagnum"],
})

export const FacialHairColor = new Piece({
    type: 'facialHairColor',
    label: 'Bartfarbe',
    options: ["Auburn", "Black", "Blonde", "BlondeGolden", "Brown", "BrownDark", "Platinum", "Red"],
})

export const ClotheType = new Piece({
    type: 'clotheType',
    label: 'Kleider',
    options: ["BlazerShirt", "BlazerSweater", "CollarSweater", "Hoodie", "Overall", "ShirtCrewNeck", "ShirtScoopNeck", "ShirtVNeck"],
    // GraphicShirt not included for less complexity
})

export const ClotheColorType = new Piece({
    type: 'clotheColor',
    label: 'Kleiderfarbe',
    options: ["Black", "Blue01", "Blue02", "Blue03", "Gray01", "Gray02", "Heather", "PastelBlue", "PastelGreen", "PastelRed", "PastelYellow", "Pink", "Red", "White"],
    // without PastelOrange
})

export const GraphicType = new Piece({
    type: 'graphicType',
    label: 'Graphic',
    options: ["Bat", "Cumbia", "Deer", "Diamond", "Hola", "Pizza", "Resist", "Selena", "Bear", "SkullOutline", "Skull"],
})

export const EyesType = new Piece({
    type: 'eyeType',
    label: 'Augen',
    options: ["Close", "Cry", "Default", "Dizzy", "EyeRoll", "Happy", "Hearts", "Side", "Squint", "Surprised", "Wink", "WinkWacky"],
})

export const EyebrowType = new Piece({
    type: 'eyebrowType',
    label: 'Augenbraue',
    options: ["Angry", "AngryNatural", "Default", "DefaultNatural", "FlatNatural", "FrownNatural", "RaisedExcited", "RaisedExcitedNatural", "SadConcerned", "SadConcernedNatural", "UnibrowNatural", "UpDown", "UpDownNatural"],
})

export const MouthType = new Piece({
    type: 'mouthType',
    label: '👄 Mouth',
    options: ["Concerned", "Default", "Disbelief", "Eating", "Grimace", "Sad", "ScreamOpen", "Serious", "Smile", "Tongue", "Twinkle", "Vomit"],
})

export const SkinType = new Piece({
    type: 'skinColor',
    label: 'Hautfarbe',
    options: ["Tanned", "Yellow", "Pale", "Light", "Brown", "DarkBrown", "Black"],
})

// only Pieces to Show in Creator (without MouthType, EyesType, EyebrowType
// GraphicType is banned totally
export const AllPieces = [
    SkinType, TopType, HatColorType, HairColorType, FacialHairType, FacialHairColor, ClotheType, ClotheColorType, AccessoriesType
]