import AvatarOptions from "./AvatarOptions";
import Avatar from "avataaars";
import React from "react";
import styled from 'styled-components'
import {EmotionOption, NeutralEmotion} from "./EmotionOption";

const Wrapper = styled.div`
  cursor: pointer;
  border-style: ${props => props.active ? "dotted" : "none"};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0;
  margin: 0;
`;

const CustomAvatar = ({big, active, emotion, avatarOptions, setAvatarOptions}) => {
    avatarOptions = new AvatarOptions(avatarOptions);
    emotion = emotion ? new EmotionOption(emotion) : NeutralEmotion;
    const style = big ? {width: '200px', height: '200px'} : {width: '100px', height: '100px'};

    return (
        <Wrapper
            onClick={() => setAvatarOptions && setAvatarOptions(avatarOptions)}
            active={active}
        >
        <Avatar
            style={style}
            topType={avatarOptions.topType}
            accessoriesType={avatarOptions.accessoriesType}
            hairColor={avatarOptions.hairColor}
            hatColor={avatarOptions.hatColor}
            facialHairType={avatarOptions.facialHairType}
            facialHairColor={avatarOptions.facialHairColor}
            clotheType={avatarOptions.clotheType}
            clotheColor={avatarOptions.clotheColor}
            eyeType={emotion.eyeType}
            eyebrowType={avatarOptions.eyebrowType}
            graphicType={avatarOptions.graphicType}
            mouthType={emotion.mouthType}
            skinColor={avatarOptions.skinColor}
        />
        </Wrapper>
    );
}

export default CustomAvatar;
