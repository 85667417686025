/**
 * EmotionOption model
 *
 */
export class EmotionOption {
    constructor(data = {}) {
        this.eyeType = null;
        this.eyebrowType = null;
        this.mouthType = null;
        Object.assign(this, data);
    }
}

export const HappyEmotion = new EmotionOption({eyeType: 'Happy', eyebrowType: 'DefaultNatural', mouthType: 'Smile'});
export const NeutralEmotion = new EmotionOption({eyeType: 'Default', eyebrowType: 'DefaultNatural', mouthType: 'Default'});
export const SadEmotion = new EmotionOption({eyeType: 'Close', eyebrowType: 'SadConcernedNatural', mouthType: 'Sad'});
