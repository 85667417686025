import React from "react";
import styled from 'styled-components'
import AvatarOptions from "./AvatarOptions";
import CustomAvatar from "./CustomAvatar";
import {AllPieces, Piece} from "./Pieces";
import {HappyEmotion} from "./EmotionOption";

export const AvatarList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: 320px;
  overflow: auto;
  justify-content: space-between;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const SentenceStyle = styled.p`
  text-align: center;
  font-weight: bold;
  padding: 0;
  margin: 0.5rem;
`;

const Button = styled.button`
  background-color: #f44336;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
  &:disabled {
    background-color: grey;
  }
`;

const Customizer = ( {avatarOptions, piece, setAvatarOptions, previousPiece, nextPiece, currentPiece} ) => {
    avatarOptions = new AvatarOptions(avatarOptions);
    piece = new Piece(piece);

    return (
        <div>
            <Header>
                <Button disabled={currentPiece === 0} onClick={() => previousPiece()} >&larr;</Button>
                <SentenceStyle>{piece.label}</SentenceStyle>
                <Button disabled={currentPiece === AllPieces.length - 1} onClick={() => nextPiece()} >&rarr;</Button>
            </Header>
            <AvatarList>
                {piece.options.map(option => {
                    const newAvatarOptions = {...avatarOptions, ...{[piece.type]: option}}
                    return (
                        <CustomAvatar emotion={HappyEmotion} active={AvatarOptions.equals(newAvatarOptions, avatarOptions)} setAvatarOptions={setAvatarOptions} avatarOptions={newAvatarOptions} />
                    )
                })}
            </AvatarList>
        </div>
    );
}

export default Customizer;
