/**
 * AvatarOptions model
 *
 */
class AvatarOptions {
    constructor(data = {}) {
        this.topType = null;
        this.accessoriesType = null;
        this.hairColor = null;
        this.hatColor = null;
        this.facialHairType = null;
        this.facialHairColor = null;
        this.clotheType = null;
        this.clotheColor = null;
        //this.eyeType = null;
        //this.eyebrowType = null;
        //this.mouthType = null;
        this.skinColor = null;

        Object.assign(this, data);
    }

    static equals(one, other) {
        return (
            one.topType === other.topType  &&
            one.accessoriesType === other.accessoriesType  &&
            one.hairColor === other.hairColor  &&
            one.hatColor === other.hatColor  &&
            one.facialHairType === other.facialHairType  &&
            one.facialHairColor === other.facialHairColor  &&
            one.clotheType === other.clotheType  &&
            one.clotheColor === other.clotheColor  &&
            one.skinColor === other.skinColor
        );
    }
}
export default AvatarOptions;